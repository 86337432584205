
import React from 'react';
import {useState, useEffect} from 'react';

import {
    List,
    Datagrid,
    TextField,
    NumberField,
    ReferenceField,
    AutocompleteInput,
    FunctionField,
    DateField,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    SearchInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
    SimpleFormIterator,
    DateTimeInput,
    DateInput,
    ArrayInput,
    ArrayField,
    EmailField,
    useRecordContext,
    BooleanInput,
    BooleanField,
    required,
} from 'react-admin';

import {
    StandardPagination,
    RoomWithBuildingIdentifier
} from '../common.js'


export const AssetParentIdentifier = (record) => {
    if (record === null)
        return null

    return record.room ? `${record.name} - ${record.room.name ?? record.room.nice_name} - ${record.building.name ? record.building.name : record.building.address}`
      : `${record.name} - ${record.room_id} - ${record.id}`;
}

export const AssetCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput source="asset_category_id" reference="asset_categories" perPage={1000} validate={[required()]}><AutocompleteInput
                optionText="name"/></ReferenceInput>
            <ReferenceInput source="room_id" reference="rooms" perPage={1000} validate={[required()]}><SelectInput
                optionText="name"/></ReferenceInput>
            <TextInput source="room_position_id" />
            <TextInput source="model" validate={[required()]}/>
            <TextInput source="name" validate={[required()]}/>
            <TextInput source="nice_name" />
            <ReferenceInput source="parent_id" reference="assets" perPage={10000}>
                <AutocompleteInput optionText={AssetParentIdentifier}/>
            </ReferenceInput>
            <TextInput source="description" />

        </SimpleForm>
    </Create>
);

export const AssetEdit = props => {

    const transform = data => {
        delete data["building"]
        delete data["room"]
        return data
      }


  return (
    <Edit {...props} transform={transform}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <ReferenceInput source="asset_category_id" reference="asset_categories" perPage={1000}>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <TextInput source="model" />
            <TextInput source="name" />
            <TextInput source="nice_name" />
            <TextInput source="description" />
            <ReferenceInput source="parent_id" reference="assets" perPage={10000}><AutocompleteInput optionText={AssetParentIdentifier} /></ReferenceInput>
            <ReferenceInput source="room_id" reference="rooms" perPage={1000}><SelectInput optionText="name" /></ReferenceInput>
            <TextInput source="room_position_id" />
        </SimpleForm>
    </Edit>
)
    };

const listFilters = [
    <SearchInput source="q" alwaysOn/>,
    <ReferenceArrayInput source="room_id" reference="rooms" label="Rooms" perPage={10000} debounce={-1} alwaysOn>
      <AutocompleteArrayInput optionText={RoomWithBuildingIdentifier} optionValue={"id"}/>
    </ReferenceArrayInput>,
    <ReferenceArrayInput source="asset_category_id" reference="asset_categories" label="Categories" perPage={10000} alwaysOn>
        <AutocompleteArrayInput optionText="name"/>
    </ReferenceArrayInput>,
]


export const AssetList = props => (
    <List pagination={<StandardPagination />} filters={listFilters} perPage={25} {...props} >
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="model" />
            <TextField source="name" />
            <TextField source="nice_name" />
            <ReferenceField source="parent_id" reference="assets"><TextField source="name" /></ReferenceField>
            <ReferenceField source="asset_category_id" reference="asset_categories"><TextField source="name" /></ReferenceField>
            <ReferenceField source="room_id" reference="rooms"><TextField source="name" /></ReferenceField>
            <ReferenceField source="room.building_id" reference="buildings"><TextField source="name" /></ReferenceField>
            <TextField source="room_position_id" />
            <TextField source="description" />
            <TextField source="registered_by" />
            <DateField source="created_at" />
        </Datagrid>
    </List>
);
