
import React from 'react';

import {
    List,
    Datagrid,
    TextField,
    NumberField,
    ReferenceField,
    AutocompleteInput,
    FunctionField,
    DateField,
} from 'react-admin';

import {
    Edit,
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    SearchInput,
    NumberInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
} from 'react-admin';

import {
    StandardPagination
} from '../common.js'

const deviceListFilters = [
    <ReferenceArrayInput source="organization_id" reference="organizations" label="Organizations" perPage={10000} alwaysOn>
        <AutocompleteArrayInput optionText="name"/>
    </ReferenceArrayInput>,
    <ReferenceArrayInput source="type_id" reference="devicetypes" label="Types" perPage={10000} alwaysOn>
        <AutocompleteArrayInput optionText="model"/>
    </ReferenceArrayInput>,
    <SearchInput source="q" alwaysOn/>,
]

export const DeviceList = props => (
    <List pagination={<StandardPagination />} filters={deviceListFilters} perPage={25} {...props}>
        <Datagrid rowClick="edit">
            <TextField label="Id" source="id"/>
            <TextField source="serial"/>
            <TextField label="Hardware ID" source="device_id"/>
            <ReferenceField label="Type" source="type_id" reference="devicetypes">
                <FunctionField render={record => `${record.brand} ${record.model}`}/>
            </ReferenceField>
            <ReferenceField source="organization_id" reference="organizations"><TextField source="name"/></ReferenceField>
            <DateField source="registered_at" label='Registered' showTime/>
            <TextField source="nickname"/>
            <TextField source="description"/>
        </Datagrid>
    </List>
);

//Wont work, edit device uses patch method, not put..
export const DeviceEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextField label="Id" source="id"/>
            <TextField source="serial"/>
            <TextField label="Hardware ID" source="device_id"/>
            <ReferenceField label="Type" source="type_id" reference="devicetypes">
                <FunctionField render={record => `${record.brand} ${record.model}`}/>
            </ReferenceField>
            <ReferenceField label="Organization" source="organization_id" reference="organizations"><TextField source="name"/></ReferenceField>
            <ReferenceField label="Creator" source="registered_by" reference="users"><TextField source="name"/></ReferenceField>
            <DateField source="registered_at" label='Registered' showTime/>
            <TextInput source="nickname"/>
            <TextInput source="description"/>
        </SimpleForm>
    </Edit>
);

export const DeviceCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput label='Device type' reference='devicetypes' source="type">
                <SelectInput optionText="model"/>
            </ReferenceInput>
            <TextInput source="serial"/>
            <TextInput source="revision"/>
            <TextInput source="api_key"/>
        </SimpleForm>
    </Create>
);
